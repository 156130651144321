export var Template;

(function (Template) {
  Template["ADDI_TEMPLATE_01"] = "ADDI_TEMPLATE_01";
  Template["ADDI_TEMPLATE_02"] = "ADDI_TEMPLATE_02";
  Template["ADDI_TEMPLATE_4X"] = "ADDI_TEMPLATE_4X";
})(Template || (Template = {}));

export var ProductType;

(function (ProductType) {
  ProductType["FINANCIA"] = "ADDI_FINANCIA";
  ProductType["PAGO"] = "ADDI_PAGO";
})(ProductType || (ProductType = {}));