import styled from 'styled-components';
import { ColorAddiFontSecondary, ColorAddiCompFiveBase } from '@addi/foundation/dist/web/colors';
import { Paragraph, PrimaryButton, Title } from '@addi/ui';
import { FontAddiWeightBold, FontAddiWeightRegular, FontAddiWeightSemiBold } from '@addi/foundation/dist/web/fonts';
import { SizeAddiFontXSmall, SizeAddiFontBase } from '@addi/foundation/dist/web/sizes';
import { LayoutAddiScreenDesktopMin, LayoutAddiScreenMobileLargeMax } from '@addi/foundation/dist/web/layout';
export var TemplateHeader = styled.div.withConfig({
  displayName: "nopreapprovalstyled__TemplateHeader",
  componentId: "sc-1epz9g4-0"
})(["img{margin-top:1rem;}"]);
export var TemplateTitle = styled(Title).withConfig({
  displayName: "nopreapprovalstyled__TemplateTitle",
  componentId: "sc-1epz9g4-1"
})(["font-weight:", ";margin:1.5rem 0 0.5rem 0;text-align:start;span{display:block;}@media (max-width:", "){font-size:22px;}"], FontAddiWeightBold, LayoutAddiScreenMobileLargeMax);
export var TemplateContainer = styled.div.withConfig({
  displayName: "nopreapprovalstyled__TemplateContainer",
  componentId: "sc-1epz9g4-2"
})(["height:86vh;display:flex;flex-direction:column;justify-content:space-between;"]);
export var TemplateSubtitle = styled(Paragraph).withConfig({
  displayName: "nopreapprovalstyled__TemplateSubtitle",
  componentId: "sc-1epz9g4-3"
})(["text-align:initial;font-weight:", ";font-size:", ";color:", ";margin:0;line-height:1.48;"], FontAddiWeightRegular, SizeAddiFontXSmall, ColorAddiFontSecondary);
export var IconsContainer = styled.div.withConfig({
  displayName: "nopreapprovalstyled__IconsContainer",
  componentId: "sc-1epz9g4-4"
})(["display:flex;margin-top:", ";flex-direction:column;img{width:25px;padding-top:5px;}"], SizeAddiFontBase);
export var IconSection = styled.div.withConfig({
  displayName: "nopreapprovalstyled__IconSection",
  componentId: "sc-1epz9g4-5"
})(["display:flex;align-items:center;margin-top:1rem;i{color:", ";padding-top:5px;}"], ColorAddiCompFiveBase);
export var IconText = styled(Paragraph).withConfig({
  displayName: "nopreapprovalstyled__IconText",
  componentId: "sc-1epz9g4-6"
})(["margin:0 0 0 0.5rem;font-size:14px;font-weight:", ";padding-top:6px;"], FontAddiWeightSemiBold);
export var IconTextMiddle = styled(IconText).withConfig({
  displayName: "nopreapprovalstyled__IconTextMiddle",
  componentId: "sc-1epz9g4-7"
})(["padding-top:3px;"]);
export var SectionContainer = styled.div.withConfig({
  displayName: "nopreapprovalstyled__SectionContainer",
  componentId: "sc-1epz9g4-8"
})(["@media (min-width:", "){margin:0 auto;}"], LayoutAddiScreenDesktopMin);
export var BackButton = styled(PrimaryButton).withConfig({
  displayName: "nopreapprovalstyled__BackButton",
  componentId: "sc-1epz9g4-9"
})(["font-size:1rem !important;&&{width:100%;}"]);