import styled from 'styled-components';
import { Paragraph } from '@addi/ui';
import { FontAddiWeightRegular } from '@addi/foundation/dist/web/fonts';
import { SizeAddiFontXxSmall } from '@addi/foundation/dist/web/sizes';
import { ColorAddiGrayLighten1, ColorAddiGrayLighten3 } from '@addi/foundation/dist/web/colors';
export var LegalTermsContainer = styled.div.withConfig({
  displayName: "legal-termsstyled__LegalTermsContainer",
  componentId: "sc-1pup8e-0"
})(["display:block;width:95%;margin:auto;"]);
export var LegalParagraph = styled(Paragraph).withConfig({
  displayName: "legal-termsstyled__LegalParagraph",
  componentId: "sc-1pup8e-1"
})(["font-weight:", ";font-size:", ";color:", ";"], FontAddiWeightRegular, SizeAddiFontXxSmall, ColorAddiGrayLighten1);
export var Separator = styled.div.withConfig({
  displayName: "legal-termsstyled__Separator",
  componentId: "sc-1pup8e-2"
})(["height:1px;background-color:", ";margin:20px 0 5px 0;"], ColorAddiGrayLighten3);