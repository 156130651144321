import styled from 'styled-components';
import { Paragraph, Title, PrimaryButton } from '@addi/ui';
import { FontAddiWeightBold } from '@addi/foundation/dist/web/fonts';
import { LayoutAddiScreenMobileLargeMax, LayoutAddiScreenDesktopMin } from '@addi/foundation/dist/web/layout';
import { FontAddiWeightRegular, FontAddiWeightSemiBold } from '@addi/foundation/dist/web/fonts';
import { SizeAddiFontXSmall } from '@addi/foundation/dist/web/sizes';
import { ColorAddiFontAction, ColorAddiFontSecondary, ColorAddiFontPrimary, ColorAddiCompFiveLighten1, ColorAddiCompFiveLighten4 } from '@addi/foundation/dist/web/colors'; // GENERAL

export var TemplateContainer = styled.div.withConfig({
  displayName: "addi-template-4xstyled__TemplateContainer",
  componentId: "sc-1cjx18y-0"
})(["@media (min-width:420px){padding:0 16px;}height:96vh;display:flex;flex-direction:column;justify-content:space-between;"]);
export var SectionContainer = styled.div.withConfig({
  displayName: "addi-template-4xstyled__SectionContainer",
  componentId: "sc-1cjx18y-1"
})(["@media (min-width:", "){margin:0 auto;}"], LayoutAddiScreenDesktopMin);
export var TemplateHeader = styled.div.withConfig({
  displayName: "addi-template-4xstyled__TemplateHeader",
  componentId: "sc-1cjx18y-2"
})(["img{margin-top:1rem;}"]);
export var TemplateTitle = styled(Title).withConfig({
  displayName: "addi-template-4xstyled__TemplateTitle",
  componentId: "sc-1cjx18y-3"
})(["font-weight:", ";margin:1.5rem 0 0.5rem 0;text-align:start;span{display:block;}@media (max-width:", "){font-size:22px;}"], FontAddiWeightBold, LayoutAddiScreenMobileLargeMax);
export var TemplateSubtitle = styled(Paragraph).withConfig({
  displayName: "addi-template-4xstyled__TemplateSubtitle",
  componentId: "sc-1cjx18y-4"
})(["text-align:initial;font-weight:", ";font-size:", ";color:", ";margin:0;line-height:1.48;"], FontAddiWeightRegular, SizeAddiFontXSmall, ColorAddiFontSecondary);
export var IconsContainer = styled.div.withConfig({
  displayName: "addi-template-4xstyled__IconsContainer",
  componentId: "sc-1cjx18y-5"
})(["display:flex;margin-top:2.75rem;flex-direction:column;img{width:25px;padding-top:5px;}"]);
export var IconSection = styled.div.withConfig({
  displayName: "addi-template-4xstyled__IconSection",
  componentId: "sc-1cjx18y-6"
})(["display:flex;align-items:center;margin-top:1rem;i{color:#32bcad;padding-top:5px;}"]);
export var IconText = styled(Paragraph).withConfig({
  displayName: "addi-template-4xstyled__IconText",
  componentId: "sc-1cjx18y-7"
})(["margin:0 0 0 0.5rem;font-size:14px;font-weight:", ";padding-top:2px;"], FontAddiWeightSemiBold);
export var SubmitButton = styled(PrimaryButton).withConfig({
  displayName: "addi-template-4xstyled__SubmitButton",
  componentId: "sc-1cjx18y-8"
})(["font-size:1rem !important;&&{width:100%;}i{margin-left:5px;}"]);
export var InputContainer = styled.div.withConfig({
  displayName: "addi-template-4xstyled__InputContainer",
  componentId: "sc-1cjx18y-9"
})(["margin-top:1.5rem;"]);
export var BackButton = styled.div.withConfig({
  displayName: "addi-template-4xstyled__BackButton",
  componentId: "sc-1cjx18y-10"
})(["display:flex;justify-content:flex-start;"]); // INSTALLMENTS SECTION

export var InstallmentsContainer = styled.div.withConfig({
  displayName: "addi-template-4xstyled__InstallmentsContainer",
  componentId: "sc-1cjx18y-11"
})(["display:flex;flex-direction:column;margin-top:30px;gap:8px;"]);
export var StepContainer = styled.div.withConfig({
  displayName: "addi-template-4xstyled__StepContainer",
  componentId: "sc-1cjx18y-12"
})(["display:flex;align-items:center;gap:12px;"]);
export var Step = styled.div.withConfig({
  displayName: "addi-template-4xstyled__Step",
  componentId: "sc-1cjx18y-13"
})(["border-radius:50%;padding:3px 7px;background-color:", ";"], function (_ref) {
  var backgroundColor = _ref.backgroundColor;
  return backgroundColor || ColorAddiCompFiveLighten4;
});
export var Info = styled(Paragraph).withConfig({
  displayName: "addi-template-4xstyled__Info",
  componentId: "sc-1cjx18y-14"
})(["line-height:16px;text-align:left;padding:0 8px;&&{font-size:12px;}"]);
export var SummaryTitle = styled(Title).withConfig({
  displayName: "addi-template-4xstyled__SummaryTitle",
  componentId: "sc-1cjx18y-15"
})(["font-weight:", ";margin:1.5rem 0 0.5rem 0;text-align:start;span{color:", ";}@media (max-width:", "){font-size:22px;}"], FontAddiWeightBold, ColorAddiFontAction, LayoutAddiScreenMobileLargeMax);
export var SummarySubtitle = styled.p.withConfig({
  displayName: "addi-template-4xstyled__SummarySubtitle",
  componentId: "sc-1cjx18y-16"
})(["font-weight:", ";font-size:16px;color:", ";margin-top:2.5rem;font-family:'Inter',sans-serif;text-align:start;"], FontAddiWeightSemiBold, ColorAddiFontPrimary);
export var SummaryTopicContainer = styled.div.withConfig({
  displayName: "addi-template-4xstyled__SummaryTopicContainer",
  componentId: "sc-1cjx18y-17"
})(["display:flex;margin-top:1rem;align-items:center;p{font-size:14px;margin:0;white-space:nowrap;display:flex;text-align:start;}span{display:flex;align-items:center;justify-content:center;background-color:", ";border-radius:20px;height:20px;width:20px;color:", ";font-weight:700;margin-right:10px;}&:last-child{p{", ";}}"], ColorAddiCompFiveLighten4, ColorAddiCompFiveLighten1, function (_ref2) {
  var ally = _ref2.ally;
  return ally === 'inStore' && 'white-space: normal !important';
});
export var AddiLogo = styled.img.withConfig({
  displayName: "addi-template-4xstyled__AddiLogo",
  componentId: "sc-1cjx18y-18"
})(["width:40px;margin:0 0 0.5rem 0.5rem;margin-top:0 !important;"]);
export var NoPreApprovalContainer = styled.div.withConfig({
  displayName: "addi-template-4xstyled__NoPreApprovalContainer",
  componentId: "sc-1cjx18y-19"
})(["@media (min-width:420px){padding:0 16px;}"]);