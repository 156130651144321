import { AssetAddiLogoSvgUrl } from '@addi/foundation/dist/web/assets';
import { ColorAddiCompFiveLighten4, ColorAddiFontAction } from '@addi/foundation/dist/web/colors';
export var allyColor = function allyColor(allySlug) {
  switch (allySlug) {
    default:
      return {
        main: ColorAddiFontAction,
        secondary: ColorAddiCompFiveLighten4
      };
  }
};
export var AddiLogoSrc = function AddiLogoSrc(allySlug) {
  switch (allySlug) {
    default:
      return AssetAddiLogoSvgUrl;
  }
};