export var translationKeys = {
  co: {
    APPLICABILITY_MESSAGE: 'Para saber si eres aplicable y agilizar tu compra',
    DISCOUNT_FINE_PRINT: 'Sujeto a verificación de identidad y perfil crediticio. Aplican intereses y costo de respaldo.',
    DISCOUNT_FINE_PRINT_ZERO_INTEREST: 'Sujeto a verificación de identidad y perfil crediticio.',
    DISCOUNT_PRICE: '{0} COP*',
    DISCOUNT_PRICE_LABEL: 'Precio pagando con ADDI',
    FIND_US_PAYMENT_METHODS: 'Encuéntranos en los métodos de pago',
    FINE_PRINT: 'Sujeto a verificación de identidad y perfil crediticio. Aplican intereses y costo de respaldo.',
    FINE_PRINT_ZERO_INTEREST: 'Sujeto a verificación de identidad y perfil crediticio.',
    FINE_PRINT_MIN_AMOUNT: 'Aplica para compras de mínimo ${0}.',
    INSTALLMENT_LABEL: '$ {0} COP*',
    NORMAL_PRICE: '$ {0} COP',
    NORMAL_PRICE_LABEL: 'Precio original',
    ONBOARDING_TITLE: 'Compra ahora y págalo',
    PAY_WITH_ADDI: 'Paga después con',
    PREAPPROVAL_TITLE: 'Obtenga su limite de pre-aprobado',
    PREAPPROVAL_LINK: 'Obtén tu cupo y agiliza tu compra',
    TERM_COST_LABEL: 'en {0} cuotas mensuales de',
    TERM_COST_MIN_MAX_LABEL: 'Desde {0} hasta {1} cuotas mensuales',
    ZERO_INTEREST_LABEL: '0% Interés',
    CURRENCY_SYMBOL: '$'
  },
  pt: {
    APPLICABILITY_MESSAGE: 'Descubra se está disponível para você e agilize sua compra',
    DISCOUNT_FINE_PRINT: 'Aplicam-se termos e condições.',
    DISCOUNT_FINE_PRINT_ZERO_INTEREST: 'Aplicam-se termos e condições.',
    DISCOUNT_PRICE: '{0} *',
    DISCOUNT_PRICE_LABEL: 'Preço pagando com ADDI',
    FIND_US_PAYMENT_METHODS: 'Selecione a gente na página de pagamento.',
    FINE_PRINT: 'Sujeito à verificação de identidade e perfil de crédito.',
    FINE_PRINT_ZERO_INTEREST: 'Sujeito à verificação de identidade e perfil de crédito.',
    FINE_PRINT_MIN_AMOUNT: 'Disponível somente para compras acima de R$ {0}.',
    NORMAL_PRICE: 'R$ {0} ',
    NORMAL_PRICE_LABEL: 'Preço original',
    ONBOARDING_TITLE: 'Compre agora e pague',
    PAY_WITH_ADDI: 'Pague parcelado com',
    PREAPPROVAL_TITLE: 'Obtenha seu limite pré-aprovado',
    PREAPPROVAL_LINK: 'Obtenha seu ApprovADDI',
    INSTALLMENT_LABEL: 'R$ {0} *',
    TERM_COST_LABEL: 'Em até {0} parcelas mensais de',
    TERM_COST_LABEL_DOWNPAYMENTS: 'Em 1 + {0} parcelas mensais de',
    TERM_COST_MIN_MAX_LABEL: 'De {0} até {1} parcelas por mês',
    ZERO_INTEREST_LABEL: 'Sem juros',
    CURRENCY_SYMBOL: 'R$'
  }
};