import styled from 'styled-components';
import { ColorAddiBackgroundWhite, ColorAddiCompFiveLighten1, ColorAddiCompFiveLighten4 } from '@addi/foundation/dist/web/colors';
import { Paragraph, Subtitle } from '@addi/ui';
export var InstallmentWrapper = styled.div.withConfig({
  displayName: "installmentstyled__InstallmentWrapper",
  componentId: "sc-1acf1w5-0"
})(["display:flex;justify-content:space-between;padding:8px;border-radius:5px;background-color:", ";"], ColorAddiBackgroundWhite);
export var Price = styled(Paragraph).withConfig({
  displayName: "installmentstyled__Price",
  componentId: "sc-1acf1w5-1"
})(["line-height:20px;margin:0;&&{font-size:14px;}"]);
export var StepContainer = styled.div.withConfig({
  displayName: "installmentstyled__StepContainer",
  componentId: "sc-1acf1w5-2"
})(["display:flex;align-items:center;gap:12px;"]);
export var StepLabel = styled(Paragraph).withConfig({
  displayName: "installmentstyled__StepLabel",
  componentId: "sc-1acf1w5-3"
})(["line-height:14px;margin:0;&&{font-size:14px;}"]);
export var StepNumber = styled(Subtitle).withConfig({
  displayName: "installmentstyled__StepNumber",
  componentId: "sc-1acf1w5-4"
})(["border-radius:50%;font-weight:700;font-size:14px;width:20px;height:20px;line-height:20px;margin:0;background-color:", ";&&{font-size:14px;color:", ";}"], function (_ref) {
  var backgroundColor = _ref.backgroundColor;
  return backgroundColor || ColorAddiCompFiveLighten4;
}, function (_ref2) {
  var color = _ref2.color;
  return color || ColorAddiCompFiveLighten1;
});