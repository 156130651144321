export var translationKeys = {
  co: {
    DISCOUNT_FINE_PRINT: 'Sujeto a verificación de identidad y perfil crediticio. Aplican intereses y costo de respaldo.',
    DISCOUNT_FINE_PRINT_ZERO_INTEREST: 'Sujeto a verificación de identidad y perfil crediticio.',
    FIND_US_PAYMENT_METHODS: 'Encuéntranos en los métodos de pago',
    FINE_PRINT: 'Sujeto a verificación de identidad y perfil crediticio. Aplican intereses y costo de respaldo.',
    FINE_PRINT_ZERO_INTEREST: 'Sujeto a verificación de identidad y perfil crediticio.',
    FINE_PRINT_MIN_AMOUNT: 'Aplica para compras de mínimo ${0}.',
    INSTALLMENT_LABEL: '$ {0} COP*',
    NORMAL_PRICE: '$ {0} COP',
    PAY_WITH_ADDI: 'Paga después con',
    PIX_A_VISTA: 'Pix à vista',
    PREAPPROVAL_LINK: 'Obtén tu cupo y agiliza tu compra',
    PREAPPROVAL_TITLE: 'Obtenga su limite de pre-aprobado',
    ONBOARDING_TITLE: 'Pagas tus compras en 1+3x en ',
    ONBOARDING_TITLE_STRONG: 'Pix, sin intereses y sin tarjeta!',
    PAYMENT_WAYS: 'Como puedes pagar',
    INSTALLMENT_PAYMENT: 'Cada mes',
    INSTALLMENT_BADGE: '1+3x sin intereses!',
    FIRST_PAYMENT: 'hoy',
    SECOND_PAYMENT: 'en 1 mes',
    NEXT_PAYMENT: 'en {0} meses',
    ADVISE_PAYMENT: 'El primer pago debe realizarse hoy',
    UNIQUE_BADGE: 'Pix a la vista',
    UNIQUE_PAYMENT: 'Pago unico',
    PIX_PAYMENT_WAY: 'Seleccione Addi cuotas con Pix en el método de pago.'
  },
  pt: {
    DISCOUNT_FINE_PRINT: 'Aplicam-se termos e condições.',
    DISCOUNT_FINE_PRINT_ZERO_INTEREST: 'Aplicam-se termos e condições.',
    FIND_US_PAYMENT_METHODS: 'Selecione a gente na página de pagamento.',
    FINE_PRINT: 'Sujeito à verificação de identidade e perfil de crédito.',
    FINE_PRINT_ZERO_INTEREST: 'Sujeito à verificação de identidade e perfil de crédito.',
    FINE_PRINT_MIN_AMOUNT: 'Disponível somente para compras acima de R$ {0}.',
    INSTALLMENT_LABEL: 'R$ {0} *',
    NORMAL_PRICE: 'R$ {0} ',
    PAY_WITH_ADDI: 'Pague à vista ou parcelado com',
    PIX_A_VISTA: 'Pix à vista',
    PREAPPROVAL_LINK: 'Obtenha seu ApprovADDI',
    PREAPPROVAL_TITLE: 'Obtenha seu limite pré-aprovado',
    ONBOARDING_TITLE: 'Você parcela suas compras em 1+3x no ',
    ONBOARDING_TITLE_STRONG: 'Pix, sem juros e sem cartão!',
    PAYMENT_WAYS: 'Como você pode pagar',
    INSTALLMENT_PAYMENT: 'Cada mês',
    INSTALLMENT_BADGE: '1+3x sem juros!',
    FIRST_PAYMENT: 'hoje',
    SECOND_PAYMENT: 'em 1 mês',
    NEXT_PAYMENT: 'em {0} meses',
    ADVISE_PAYMENT: 'A primeira parcela será paga hoje',
    UNIQUE_PAYMENT: 'Pagamento único',
    PIX_PAYMENT_WAY: 'Selecione Pix parcelado Addi na Forma de Pagamento.'
  }
};