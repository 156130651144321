import styled from 'styled-components';
import { Paragraph, Title } from '@addi/ui';
import { ColorAddiCompThreeLighten1, ColorAddiFontPrimary } from '@addi/foundation/dist/web/colors';
export var TitleContainer = styled.div.withConfig({
  displayName: "addi-template-02styled__TitleContainer",
  componentId: "b6i268-0"
})(["padding:10px 20px"]);
export var ModalTitle = styled(Title).withConfig({
  displayName: "addi-template-02styled__ModalTitle",
  componentId: "b6i268-1"
})(["margin:10px 0;"]);
export var TemplateContainer = styled.div.withConfig({
  displayName: "addi-template-02styled__TemplateContainer",
  componentId: "b6i268-2"
})(["padding:", ";"], function (_ref) {
  var preapprovalLink = _ref.preapprovalLink;
  return preapprovalLink ? '0 20px' : '0 20px 180px';
});
export var PaymentOptionsTitle = styled(Paragraph).withConfig({
  displayName: "addi-template-02styled__PaymentOptionsTitle",
  componentId: "b6i268-3"
})(["font-weight:700;letter-spacing:-0.01em;color:", ";margin:10px 0 2px 0"], ColorAddiFontPrimary);
export var Badge = styled.div.withConfig({
  displayName: "addi-template-02styled__Badge",
  componentId: "b6i268-4"
})(["background-color:", ";padding:2px 15px;margin:10px auto 0;border-radius:", ";"], function (_ref2) {
  var backgroundColor = _ref2.backgroundColor;
  return backgroundColor ? backgroundColor : ColorAddiCompThreeLighten1;
}, function (_ref3) {
  var borderRadius = _ref3.borderRadius;
  return borderRadius ? borderRadius : '5px';
});
export var InstallmentsContainer = styled.div.withConfig({
  displayName: "addi-template-02styled__InstallmentsContainer",
  componentId: "b6i268-5"
})(["display:flex;flex-direction:column;margin-top:10px;gap:8px;"]);
export var Info = styled(Paragraph).withConfig({
  displayName: "addi-template-02styled__Info",
  componentId: "b6i268-6"
})(["line-height:16px;text-align:center;padding:0 8px;&&{font-size:12px;}"]);