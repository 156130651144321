export var Countries;

(function (Countries) {
  Countries["Brazil"] = "br";
  Countries["Colombia"] = "co";
})(Countries || (Countries = {}));

;
export var Languages;

(function (Languages) {
  Languages["Portuguese"] = "pt";
  Languages["Spanish"] = "es";
})(Languages || (Languages = {}));