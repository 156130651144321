export var Locales = {
  'br': {
    locale: 'pt-BR',
    currency: 'BRL'
  },
  'co': {
    locale: 'es-CO',
    currency: 'COP'
  }
};
export var currencyFormatter = function currencyFormatter(value, location, withoutDecimals) {
  var _Locales$location, _Locales$location2;

  if (location === void 0) {
    location = 'co';
  }

  if (withoutDecimals === void 0) {
    withoutDecimals = false;
  }

  var formatter = new Intl.NumberFormat((_Locales$location = Locales[location]) === null || _Locales$location === void 0 ? void 0 : _Locales$location.locale, {
    style: 'currency',
    currency: (_Locales$location2 = Locales[location]) === null || _Locales$location2 === void 0 ? void 0 : _Locales$location2.currency
  });
  var formattedValue = formatter.format(value);
  return withoutDecimals ? formattedValue.split(',')[0] : formattedValue;
};