// EXTRA INFO SECTIONS
import styled from 'styled-components';
import { Paragraph } from '@addi/ui';
import { ColorAddiFontAction } from '@addi/foundation/dist/web/colors';
export var AdviseContainer = styled.div.withConfig({
  displayName: "advisestyled__AdviseContainer",
  componentId: "d07l83-0"
})(["display:flex;gap:10px;align-items:center;margin-top:6px;padding:0 8px;"]);
export var AdviceText = styled(Paragraph).withConfig({
  displayName: "advisestyled__AdviceText",
  componentId: "d07l83-1"
})(["margin:0;line-height:16px;&&{font-size:12px;color:", ";}"], ColorAddiFontAction);