import styled from 'styled-components';
import { ColorAddiBackgroundWhite, ColorAddiCompThreeBase, ColorAddiCompThreeLighten5, ColorAddiFontPrimary, ColorAddiFontSecondary, ColorAddiGrayLighten2 } from '@addi/foundation/dist/web/colors';
import { Paragraph } from '@addi/ui';
export var PaymentBadge = styled.div.withConfig({
  displayName: "payment-typestyled__PaymentBadge",
  componentId: "sc-13ag1f4-0"
})(["display:flex;align-items:center;background:", ";border-radius:4px;padding:2px 8px;height:min-content;"], ColorAddiCompThreeLighten5);
export var PaymentTitle = styled(Paragraph).withConfig({
  displayName: "payment-typestyled__PaymentTitle",
  componentId: "sc-13ag1f4-1"
})(["font-weight:400;color:", ";margin:0;"], ColorAddiFontSecondary);
export var PaymentBadgeLabel = styled(Paragraph).withConfig({
  displayName: "payment-typestyled__PaymentBadgeLabel",
  componentId: "sc-13ag1f4-2"
})(["margin:5px 0 0;color:", ";font-size:12px;font-weight:600;"], ColorAddiCompThreeBase);
export var PaymentTypeContainer = styled.div.withConfig({
  displayName: "payment-typestyled__PaymentTypeContainer",
  componentId: "sc-13ag1f4-3"
})(["padding:8px;background:", ";border:1px solid ", ";border-radius:4px;margin:8px 0"], ColorAddiBackgroundWhite, ColorAddiGrayLighten2);
export var PaymentTitleContainer = styled.div.withConfig({
  displayName: "payment-typestyled__PaymentTitleContainer",
  componentId: "sc-13ag1f4-4"
})(["text-align:left;"]);
export var PaymentTypeHeader = styled.div.withConfig({
  displayName: "payment-typestyled__PaymentTypeHeader",
  componentId: "sc-13ag1f4-5"
})(["display:flex;flex-direction:row;width:100%;justify-content:space-between;margin-bottom:15px"]);
export var PaymentTotal = styled(Paragraph).withConfig({
  displayName: "payment-typestyled__PaymentTotal",
  componentId: "sc-13ag1f4-6"
})(["font-family:'Mallory';font-style:normal;font-weight:700;font-size:16px;line-height:120%;letter-spacing:-0.01em;color:", ";flex:none;order:0;flex-grow:0;margin:2px 0"], ColorAddiFontPrimary);