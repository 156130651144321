import styled from 'styled-components';
import { PrimaryButton, Caption } from '@addi/ui';
import { ColorAddiPrimaryActive, ColorAddiFontOverDark, ColorAddiFontPrimary, ColorAddiFontSecondary } from '@addi/foundation/dist/web/colors';
import { SizeAddiFontSmall, SizeAddiFontXSmall } from '@addi/foundation/dist/web/sizes';
import { FontAddiWeightBold } from '@addi/foundation/dist/web/fonts';
export var TextBold = styled(Caption).withConfig({
  displayName: "Commonstyled__TextBold",
  componentId: "sc-8gyqo6-0"
})(["font-size:", ";font-weight:", ";color:", ";line-height:1.2rem;margin:5px 0;display:block;"], SizeAddiFontSmall, FontAddiWeightBold, function (_ref) {
  var color = _ref.color;
  return color ? color : ColorAddiFontSecondary;
});
export var PreapprovalButton = styled(PrimaryButton).withConfig({
  displayName: "Commonstyled__PreapprovalButton",
  componentId: "sc-8gyqo6-1"
})(["border:2px solid ", ";border-radius:", ";color:", ";background-color:", ";margin:10px 0 5px;font-size:", ";"], function (_ref2) {
  var borderColor = _ref2.borderColor;
  return borderColor ? borderColor : ColorAddiFontOverDark;
}, function (_ref3) {
  var borderRadius = _ref3.borderRadius;
  return borderRadius ? borderRadius : '24px';
}, function (_ref4) {
  var fontColor = _ref4.fontColor;
  return fontColor ? fontColor : ColorAddiFontOverDark;
}, function (_ref5) {
  var backgroundColor = _ref5.backgroundColor;
  return backgroundColor ? backgroundColor : ColorAddiPrimaryActive;
}, SizeAddiFontXSmall);
export var Text = styled(Caption).withConfig({
  displayName: "Commonstyled__Text",
  componentId: "sc-8gyqo6-2"
})(["color:", ";line-height:0.9rem;margin:5px 0;"], function (_ref6) {
  var color = _ref6.color;
  return color ? color : ColorAddiFontPrimary;
});
export var AddiLogoInline = styled.img.withConfig({
  displayName: "Commonstyled__AddiLogoInline",
  componentId: "sc-8gyqo6-3"
})(["width:", ";margin-left:6px;"], function (_ref7) {
  var width = _ref7.width;
  return width ? width : '40px';
});
export var PreapprovalBRContainer = styled.section.withConfig({
  displayName: "Commonstyled__PreapprovalBRContainer",
  componentId: "sc-8gyqo6-4"
})(["display:flex;flex-direction:column;margin:", ""], function (_ref8) {
  var margin = _ref8.margin;
  return margin ? margin : 0;
});